import React from 'react'
import Layout from '../components/layout'
import DSG from '../downloads/OBT-Allgemeine-DSE-.pdf'

const Datenschutzerklaerung = (props) => {
 

  return (
    <Layout>
      <section className="section-wrapper">
        <header className="section-header">
          <h3 name="title">Datenschutzerklärung</h3>
        </header>
        <span name="body">
          <p>
          Der Schutz der Privatsphäre und namentlich der Datenschutz ist der OBT Gruppe sehr wichtig. Gegenstand des Datenschutzes sind personenbezogene Daten (nachfolgend auch Daten). Dies sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Hierunter fallen z.B. Angaben wie Name, Adresse, Beruf, E-Mail-Adresse, Gesundheitszustand, Einkommen, Familienstand, genetische Merkmale, Telefonnummer und gegebenenfalls auch Nutzerdaten wie die IP-Adresse.
          </p>
          <p>
          Die vorliegende Datenschutzerklärung richtet sich nach den Bestimmungen der Europäischen Datenschutz-Grundverordnung (DS-GVO) sowie den Bestimmungen des schweizerischen Datenschutzgesetzes (DSG).
          </p>
          <br />
        </span>
        <div className="download-center">
        <a href= {DSG} target="_blank" rel="noopener noreferrer" className="button-link">Datenschutzerklärung</a>
        </div>
      </section>
    </Layout>
  )
}

export default Datenschutzerklaerung

